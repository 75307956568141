import React, { useState, useEffect } from 'react';

const PasswordPrompt = ({ autoLogin, onSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (autoLogin) {
      // Simulate typing effect
      const simulatedTyping = (text, callback) => {
        let index = 0;
        const interval = setInterval(() => {
          setPassword(text.slice(0, index + 1));
          index++;
          if (index === text.length) {
            clearInterval(interval);
            callback(); // Trigger success callback after typing is complete
          }
        }, 100); // Adjust typing speed
      };

      simulatedTyping('yourpassword', () => {
        setTimeout(() => {
          console.log('Simulated auto-login complete');
          onSuccess(); // Trigger success callback after a short delay
        }, 500); // Short delay to simulate user reviewing input
      });
    }
  }, [autoLogin, onSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'yourpassword') {
      console.log('Password submitted successfully');
      onSuccess(); // Trigger success callback
    } else {
      setError('Incorrect password'); // Display error message
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'black',
      color: 'green',
      fontFamily: 'monospace',
      padding: '20px',
      boxSizing: 'border-box',
    }}>
      <h1>Password Required</h1>
      <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            fontSize: '16px',
            color: 'green',
            backgroundColor: 'black',
            border: '1px solid green',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            outline: 'none'
          }}
          placeholder="Enter password"
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button
          type="submit"
          style={{
            fontSize: '16px',
            color: 'black',
            backgroundColor: 'green',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default PasswordPrompt;
