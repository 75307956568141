import React, { useState, useEffect } from 'react';
import '../styles/Desktop.css'; // Import the CSS file
import { blogContent } from './BlogContent';
import { projectContent } from './ProjectContent'
import { projectLinks } from './ProjectLinks'


const Desktop = () => {
  const [time, setTime] = useState('');

  // Function to update the time
  const updateTime = () => {
    const now = new Date();
    const currentTime = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    setTime(currentTime);
  };

  // Effect to set up interval
  useEffect(() => {
    updateTime(); // Set initial time
    let now = new Date();
    let currSecond = now.second;
    const interval = setInterval(updateTime, (60000-currSecond*1000)); // Maintains accurate current time

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // State for window positions
  const [bioPosition, setBioPosition] = useState({ x: 300, y: 200 });
  const [blogPosition, setBlogPosition] = useState({ x: 350, y: 250 });
  const [historyPosition, setHistoryPosition] = useState({ x: 400, y: 300 });
  const [projectsPosition, setProjectsPosition] = useState({ x: 450, y: 350 });

  // State for windows (open/close)
  const [isBioOpen, setBioOpen] = useState(false);
  const [isBlogOpen, setBlogOpen] = useState(false);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [isProjectsOpen, setProjectsOpen] = useState(false);

  // State for blog and project sub-pages
  const [activeBlog, setActiveBlog] = useState(null);
  const [activeProject, setActiveProject] = useState(null);

  // Drag functionality
  const onMouseDown = (e, setPosition) => {
    const windowElement = e.currentTarget.parentElement;
    const shiftX = e.clientX - windowElement.getBoundingClientRect().left;
    const shiftY = e.clientY - windowElement.getBoundingClientRect().top;

    const moveWindow = (event) => {
      setPosition({ x: event.pageX - shiftX, y: event.pageY - shiftY });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', moveWindow);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', moveWindow);
    document.addEventListener('mouseup', onMouseUp);
  };

  // Close the current blog/project window and return to the directory
  const handleCloseBlog = () => {
    setActiveBlog(null); // Return to blog directory
  };

  const handleCloseProject = () => {
    setActiveProject(null); // Return to project directory
  };

  const openBlog = (id) => {
    setActiveBlog(id);
  };

  const openProject = (id) => {
    setActiveProject(id);
  };

  return (
    <div className="desktop">
      <div className="taskbar">
        <img src="/logo.png" alt="Logo" className="ico" />
        <div className="taskbar-content">
          <div className="time">{time}</div>
          <div className="battery">
            <div className="battery-icon"></div>
            <div className="battery-percentage">100%</div>
          </div>
        </div>
      </div>

      {/* Desktop Icons */}
      <div className="desktop-icons">
        <div className="icon" onDoubleClick={() => setBioOpen(true)}>
          <img src="/word_icon.png" alt="Bio Icon" className="icon-image" />
          Bio
        </div>
        <div className="icon" onDoubleClick={() => setBlogOpen(true)}>
          <img src="/directory_icon.png" alt="Blog Icon" className="icon-image" />
          Blog
        </div>
        <div className="icon" onDoubleClick={() => setHistoryOpen(true)}>
          <img src="/word_icon.png" alt="History Icon" className="icon-image" />
          Work History
        </div>
        <div className="icon" onDoubleClick={() => setProjectsOpen(true)}>
          <img src="/directory_icon.png" alt="Projects Icon" className="icon-image" />
          Projects
        </div>
        <div className="icon" onDoubleClick={() => (window.open("https://github.com/NikhailKazak/", "_blank"))}>
          <img src="/gh_logo.svg" alt="GitHub Icon" className="icon-image" />
          GitHub
        </div>
        <div className="icon" onDoubleClick={() => (window.open("https://www.linkedin.com/in/nikhail-kazak-53509b196/?originalSubdomain=ca", "_blank"))}>
          <img src="/linkedin_logo.webp" alt="LinkedIn Icon" className="icon-image" />
          LinkedIn
        </div>

      </div>

      {/* Movable Windows */}
      {isBioOpen && (
        <div className="movable-window" style={{ top: bioPosition.y, left: bioPosition.x }}>
          <div className="window-titlebar" onMouseDown={(e) => onMouseDown(e, setBioPosition)}>
            <span>Bio.docx</span>
            <button className="close-button" onClick={() => setBioOpen(false)}>X</button>
          </div>
          <div className="window-content">
            <p>Hey there, I'm Nikhail!</p>
            <br></br>
            <p>
              Now I know what you're thinking, "wow this is an pretty cool website", and
              not to toot my own horn but I'd have to agree with you.
            </p>
            <br></br>
            <p>Enough of that though, if you're here, you've come to learn a bit about me.</p>
            <br></br>
            <p>
              In short, I'm a Carleton University grad based in Ottawa (Canada) and
              I'm part of the Quality Assurance Engineering team at Warner Bros. Discovery.
            </p>
            <br></br>
            <p>
              Outside of work, I enjoy tinkering with electronics as well as programming and
              video gaming. 
            </p>
          </div>
        </div>
      )}

      {/* Blog Directory */}
      {isBlogOpen && !activeBlog && (
        <div className="movable-window" style={{ top: blogPosition.y, left: blogPosition.x }}>
          <div className="window-titlebar" onMouseDown={(e) => onMouseDown(e, setBlogPosition)}>
            <span>Blog Directory</span>
            <button className="close-button" onClick={() => setBlogOpen(false)}>X</button>
          </div>
          <div className="window-content">
            {blogContent.map((content, index) => (
              <p key={index} className="directory-item" onClick={() => openBlog(index + 1)}>
                Blog {index + 1}
              </p>
            ))}
          </div>
        </div>
      )}

      {/* Blog Sub-Pages */}
      {activeBlog && (
        <div className="movable-window" style={{ top: blogPosition.y, left: blogPosition.x }}>
          <div className="window-titlebar" onMouseDown={(e) => onMouseDown(e, setBlogPosition)}>
            <span>Blog {activeBlog}.docx</span>
            <button className="close-button" onClick={handleCloseBlog}>X</button>
          </div>
          <div className="window-content">
            <p dangerouslySetInnerHTML={{ __html: blogContent[activeBlog - 1].replace(/\n/g, '<br />') }}></p>
          </div>
        </div>
      )}

      {/* Work History */}
      {isHistoryOpen && (
        <div className="movable-window" style={{ top: historyPosition.y, left: historyPosition.x }}>
          <div className="window-titlebar" onMouseDown={(e) => onMouseDown(e, setHistoryPosition)}>
            <span>Work History</span>
            <button className="close-button" onClick={() => setHistoryOpen(false)}>X</button>
          </div>
          <div className="window-content">
            <p>Here is my work history.</p>
            <br></br>
            <p>
              Previously, I've done co-ops/internships at BlackBerry QNX and Warner Bros. Discovery.
            </p>
            <br></br>
            <p>
              Obviously, more details can be found on my LinkedIn, but to briefly summarize my
              responsibilities, at QNX, I worked as a Student Technical Writer. This allowed me to
              gain not only knowledge but experience of various technologies. I got to create and
              maintain documents highlighting use cases of the QNX operating system for industry leading
              clients; and I was able to create validation tools for various QNX cryptographic
              libraries.
            </p>
            <br></br>
            <p>
              During my co-ops at WBD I was able to focus my efforts between two teams. Initially,
              it was a bit of a bumpy road as I had to navigate the organizational restructuring of
              Discovery and WarnerMedia, but it was well worth it. I focused my time with the STB
              Automation and Quality Assurance Engineering teams. This allowed me to get an idea for
              both the hardware and software side of app development. WBD's app, HBO Max was shutdown 
              in the US and relaunched as Max in May of 2023. I not only got to experience app
              testing, and test automation, but also the installation of the necessary infrastructure
              required for remote testing and app development. Having contributed to such a project
              especially as a student was quite an experience.
            </p>
            <br></br>
            <p>
              Ultimately this led me to the decision that this is the space where I'd like to get my
              first full-time job. Currently, I work with the Quality Assurance Engineering team, and
              have since made critical contributions to the LATAM, EMEA and APAC Max app launches.
            </p>
          </div>
        </div>
      )}

      {/* Projects Directory */}
      {isProjectsOpen && !activeProject && (
        <div
          className="movable-window"
          style={{ top: projectsPosition.y, left: projectsPosition.x }}
        >
          <div
            className="window-titlebar"
            onMouseDown={(e) => onMouseDown(e, setProjectsPosition)}
          >
            <span>Projects Directory</span>
            <button className="close-button" onClick={() => setProjectsOpen(false)}>
              X
            </button>
          </div>
          <div className="window-content">
            {projectContent.map((content, index) => (
              <div key={index} className="directory-item">
                <p 
                onClick={() => openProject(index + 1)}>Project {index + 1}
                <a
                  href={projectLinks[index]} // Link to the project
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Security best practice
                >
                  <img
                    src="/gh_logo.svg" // Replace with your link icon image path
                    alt={`Project ${index + 1} Link`}
                    className="project-link-icon"
                  />
                </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}


      {/* Project Sub-Pages */}
      {activeProject && (
        <div className="movable-window" style={{ top: projectsPosition.y, left: projectsPosition.x }}>
          <div className="window-titlebar" onMouseDown={(e) => onMouseDown(e, setProjectsPosition)}>
            <span>Project {activeProject}.docx</span>
            <button className="close-button" onClick={handleCloseProject}>X</button>
          </div>
          <div className="window-content">
            <p>{projectContent[activeProject - 1]}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Desktop;
