import React, { useState, useEffect } from 'react';

const BootUpScreen = ({ onComplete }) => {
  const [messages, setMessages] = useState([
    "Initializing...",
    "Loading system files...",
    "Starting services...",
    "Welcome to my website!"
  ]);

  const [displayedMessages, setDisplayedMessages] = useState("");

  useEffect(() => {
    let messageIndex = 0;
    const messageInterval = setInterval(() => {
      if (messageIndex < messages.length) {
        // Safely add the message only if it's a valid string
        const message = messages[messageIndex];
        if (message) {
          setDisplayedMessages(prev => prev + (prev ? "\n\n" : "") + message);
        }
        messageIndex++;
      } else {
        clearInterval(messageInterval);
        setTimeout(onComplete, 1000); // Transition after 1 second
      }
    }, 2000); // Change message every 2 seconds

    return () => {
      clearInterval(messageInterval); // Clear interval on component unmount
    };
  }, [messages, onComplete]);

  return (
    <div style={{
      color: 'green',
      backgroundColor: 'black',
      height: '100vh',
      width: '100vw',
      padding: '20px',
      overflow: 'hidden', // Hide scrollbars
      boxSizing: 'border-box', // Include padding in the element's total width and height
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // Center content vertically and horizontally
      position: 'relative' // For positioning the spinner and text
    }}>
      {/* Container for spinner and text */}
      <div style={{
        textAlign: 'center',
        position: 'relative', // For absolute positioning of spinner and text
        width: '100%',
        maxWidth: '500px' // Optional: Limit the width for better readability
      }}>
        {/* Loading Icon */}
        <div style={{
          margin: '0 auto',
          border: '4px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '50%',
          borderTop: '4px solid green',
          width: '40px',
          height: '40px',
          animation: 'spin 1s linear infinite',
          position: 'relative', // Ensure icon is properly positioned within the container
          zIndex: 1 // Ensure the icon is above the text
        }}></div>

        {/* Text Container */}
        <div style={{
          marginTop: '20px', // Space between spinner and text
          whiteSpace: 'pre-wrap',
          lineHeight: '1.5',
          textAlign: 'center',
          position: 'relative',
          zIndex: 0 // Text should be below the spinner
        }}>
          <pre style={{
            margin: 0, // Remove default margin
            padding: 0 // Remove default padding
          }}>
            {displayedMessages} {/* Safeguard against undefined */}
          </pre>
        </div>
      </div>

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default BootUpScreen;
