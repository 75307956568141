import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BootUpScreen from './components/BootUpScreen';
import PasswordPrompt from './components/PasswordPrompt';
import Desktop from './components/Desktop';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [bootUpComplete, setBootUpComplete] = useState(false);

  const handleBootUpComplete = () => {
    setBootUpComplete(true);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            !bootUpComplete ? (
              <BootUpScreen onComplete={handleBootUpComplete} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={
            bootUpComplete && !loggedIn ? (
              <PasswordPrompt autoLogin={true} onSuccess={() => setLoggedIn(true)} />
            ) : (
              <Navigate to="/Desktop" />
            )
          }
        />
        {loggedIn && (
          <>
            <Route path="/Desktop" element={<Desktop />} />
          </>
        )}
        {/* Wildcard route to redirect all unmatched paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
