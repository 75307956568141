export const blogContent = [
    `January 25, 2025
    
    Hey guys! We are totally back, and with a new and improved website too!
    
    I decided to go with a bit of a retro desktop pc kinda vibe for the new site, to kinda give it some life, and honestly I really like this ngl.

    Anyways, the goal with the blog this time around is to be a bit more productive - rather than it basically being a changelog. 
    
    Ideally this will cover various topics of interest, such as technologies and stuff.
    `,
    
];
